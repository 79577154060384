import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'


import "bootstrap/dist/css/bootstrap.min.css"
import "../public/css/style.css"

//import "bootstrap"


InertiaProgress.init({ includeCSS: false , showSpinner: true});

var routeMixin = {
    //eslint-disable-next-line
    methods: { route }
}
// eslint-disable-next-line
//Vue.prototype.$route = route;

var assetMixin = {
    methods: {
        asset (str){
           if(typeof str==='undefined') str = "";
           return `${process.env.VUE_APP_PUBLIC_ASSETS_PATH}${str}`;
        }
    }
}

createInertiaApp({
    resolve: name => import(`./pages/${name}`),
    setup({ el, App, props, plugin }) {
        //App.config.globalProperties.$teste='testando';
        createApp({ render: () => h(App, props) } )
            .use(plugin)
            // eslint-disable-next-line
            .mixin(routeMixin)
            .mixin(assetMixin)
            //.component('font-awesome-icon', FontAwesomeIcon)
            .mount(el)
    },
})
